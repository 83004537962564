export default defineNuxtRouteMiddleware((context) => {
  if (process.client) {
    console.log("middleware redirect context:", context);
    const route = useRoute();
    console.log("route:", route);
    const params: any = route.params;
    const cartId: string = params.id || "";
    console.log("cartId:", cartId);
    const query = route.query;
    let path = "/cart";
    if (cartId !== "") {
      path = `/cart/${cartId}`;
    }

    if (isCustomDomain()) {
      return navigateTo({ path: "/products", query });
    } else {
      return navigateTo({ path, query });
    }
  }
});
